import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "CircleCI2.0 に移行してビルド実行速度を向上",
  "date": "2017-10-24T08:40:00.000Z",
  "slug": "entry/2017/10/24/174000",
  "tags": ["medley"],
  "hero": "./2017_10_24.png",
  "heroAlt": "circleci2.0"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部の`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/61935"
      }}>{`稲本`}</a>{`です。医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発を担当しているエンジニアです。`}</p>
    <p>{`最近ジョブメドレーでは CircleCI2.0 への移行を行いました。移行の方法はもちろん、その際に調べたこと、CircleCI の新機能を利用してどうだったかなどを書いていきたいと思います。`}</p>
    <h1>{`課題感`}</h1>
    <p>{`弊社では、全プロダクト(`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`、`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{` 、`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`、`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`)で CircleCI を利用しています。`}</p>
    <p>{`ジョブメドレーでは CI によるテスト実行に 37 分前後掛かっていました(コンテナを 2 つ利用した実行時間です)。
さらに、開発メンバーが増えて来たこともあり、CI のリソースが足りなくなり開発効率が落ちかねない状況でした。`}</p>
    <p>{`まぁよくある話ですよね。`}</p>
    <p>{`コンテナを増やすというのも解決策の一つとしてはあるのですが、速度の改善に期待が出来ると評判も良かったので CirclecCI2.0 へ移行しました。`}</p>
    <h1>{`CircleCI2.0 への移行メリット`}</h1>
    <p>{`基本的には速度の改善に期待が出来る、というのが大きなメリットではありますが、公式では以下のように記載されています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024100735.png",
      "alt": "20171024100735.png"
    }}></img>
    <p>{`抜粋ですが大きな特徴としては以下の点でしょうか。`}</p>
    <ul>
      <li parentName="ul">{`First-class Docker Support: Docker のネイティブサポートと Docker レイヤーキャッシュの導入`}</li>
      <li parentName="ul">{`Workflows: ビルド、テスト、デプロイをジョブとして柔軟に管理できるようになった`}</li>
      <li parentName="ul">{`Advanced Caching: キャッシュの保存とリストアをイメージ、ソースコード、依存関係に対して行うことができるようになった。`}</li>
    </ul>
    <p>{`この辺りの機能を活用し CI の速度改善へ繋げてみたいと思います。`}</p>
    <h1>{`ジョブメドレーのアプリケーション構成`}</h1>
    <p>{`移行の前提として、ジョブメドレーのアプリケーション構成について記載します。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024100814.png",
      "alt": "20171024100814.png"
    }}></img>
    <p>{`フロントエンドのビルドを yarn+webpack で行い、生成したアセットを public/assets へ吐き出し、manifest ファイルのパスを rails の helper 経由で取得し読み込んでいます。（Rails4.2.x）`}</p>
    <p>{`このような構成のアプリケーションを CirlceCI2.0 でビルド、テスト、デプロイ出来るようにしていきます。`}</p>
    <h1>{`config.yml の全体像`}</h1>
    <p>{`今回、作成した config.yml はこのような形になりました。`}</p>
    <p>{`ざっくりは`}</p>
    <ul>
      <li parentName="ul">{`build: bundle install, yarn install`}</li>
      <li parentName="ul">{`code_analyze: rubocop, brakeman, scss-lint`}</li>
      <li parentName="ul">{`rspec`}</li>
      <li parentName="ul">{`deploy: capistrano`}</li>
    </ul>
    <p>{`を行っており、ブランチによってどのジョブを実行するのかを workflows を利用して使い分けています。`}</p>
    <p>{`詳しい解説は以降、記載していきます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`defaults`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token important"
          }}>{`&defaults`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`working_directory`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`docker`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` circleci/ruby`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`2.4.2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`browsers
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /usr/share/zoneinfo/Asia/Tokyo
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` circleci/mysql`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`x.x.x
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /usr/share/zoneinfo/Asia/Tokyo
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` redis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`x.x.x
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /usr/share/zoneinfo/Asia/Tokyo
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`jobs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token important"
          }}>{`*defaults`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` checkout
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restore_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checksum "Gemfile.lock" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle install
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle install `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`jobs=4 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`path=vendor/bundle
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`save_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checksum "Gemfile.lock" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`paths`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` vendor/bundle
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restore_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`yarn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checksum "yarn.lock" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Yarn install
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token scalar string"
          }}>{`
            echo "Node $(node -v)"
            echo "Yarn v$(yarn --version)"
            yarn config set cache-folder ./yarn_cache
            echo "Yarn v$(yarn cache dir)"
            yarn install`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`save_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`yarn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checksum "yarn.lock" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`paths`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` node_modules
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` yarn_cache
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`persist_to_workspace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`root`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`paths`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./*
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`code_analyze`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token important"
          }}>{`*defaults`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`attach_workspace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` run rubocop
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rubocop
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` run brakeman
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec brakeman `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`qz
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` run scss`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`lint
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec scss`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`lint
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rspec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`parallelism`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token important"
          }}>{`*defaults`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`attach_workspace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restore_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`elasticsearch
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# rspec で es のコマンドを一部実行しているため、primary container 側へ install`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Elasticsearch install
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token scalar string"
          }}>{`
            wget https://download.elasticsearch.org/elasticsearch/elasticsearch/elasticsearch-x.x.x.tar.gz && \\
              tar -xvf elasticsearch-x.x.x.tar.gz && \\
              if [ -z "\`elasticsearch-x.x.x/bin/plugin -l | grep analysis-kuromoji\`" ]; then \\
              elasticsearch-x.x.x/bin/plugin -install elasticsearch/elasticsearch-analysis-kuromoji/x.x.x; fi`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`save_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`elasticsearch
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`paths`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`x.x.x
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` database create
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rake db`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`create
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`RAILS_ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` test
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` run test
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token scalar string"
          }}>{`
            circleci tests glob 'spec/**/*_spec.*' \\
              | circleci tests split --split-by=timings --timings-type=filename \\
              | tee -a /dev/stderr \\
              | xargs bundle exec rspec \\
              --profile 100 \\
              --format RspecJunitFormatter \\
              --out rspec/rspec.xml \\
              --format progress`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`RAILS_ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` test
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TEST_CLUSTER_COMMAND`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`x.x.x/bin/elasticsearch
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`store_artifacts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` artifacts/
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`store_test_results`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` rspec/
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`deploy_qa`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token important"
          }}>{`*defaults`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`attach_workspace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` run deploy
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token scalar string"
          }}>{`
            sh scripts/init_deploy.sh
            BRANCH="\${CIRCLE_BRANCH}" bundle exec cap develop deploy deploy:restart`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`deploy_only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token important"
          }}>{`*defaults`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`attach_workspace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` run deploy
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token scalar string"
          }}>{`
            BRANCH="\${CIRCLE_BRANCH}" bundle exec cap production deploy deploy:restart`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`workflows`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`workflows`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`jobs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`code_analyze`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ignore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /^sandbox.`}<span parentName="code" {...{
            "className": "token important"
          }}>{`*|^master$|^staging$/`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rspec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ignore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /^sandbox.`}<span parentName="code" {...{
            "className": "token important"
          }}>{`*|^master$|^staging$/`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`deploy_qa`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` code_analyze
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rspec
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` develop
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`deploy_only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /^sandbox.`}<span parentName="code" {...{
            "className": "token important"
          }}>{`*|^master$|^staging$/`}</span></code></pre></div>
    <h1>{`DockerImage の選定`}</h1>
    <p>{`元々、Docker を使わずに CI を回していましたが、CircleCI2.0 へ移行するに辺り Docker への利用に切り替えました。`}</p>
    <p>{`※`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/executor-types/#overview"
      }}>{`Specifying Container Images`}</a></p>
    <p>{`DockerImage は DockerHub へ登録されている`}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/circleci/"
      }}>{`CircleCI`}</a><a parentName="p" {...{
        "href": "https://hub.docker.com/r/circleci/"
      }}>{`公式のも`}</a><a parentName="p" {...{
        "href": "https://hub.docker.com/r/circleci/"
      }}>{`の`}</a>{`を利用しました。`}</p>
    <p>{`アプリケーションの一部で React を使用しており、フロントのビルドは yarn+webpack を利用しています。その為、以下の image を選択しました。`}</p>
    <ul>
      <li parentName="ul">{`circleci/ruby:2.4.2-node-browsers`}
        <ul parentName="li">
          <li parentName="ul">{`node のインストールと、E2E のテストに必要なソフトウェアがインストールされています。`}</li>
          <li parentName="ul">{`※詳細は`}<a parentName="li" {...{
              "href": "https://qiita.com/inuscript/items/09d15ee52b1657872f80"
            }}>{`こちらの記事`}</a>{`を参考にさせていただきました。`}</li>
        </ul>
      </li>
    </ul>
    <p>{`その他、現在利用している MySQL のバージョン、ElasticCacheRedis のバージョンと合わせた image を選択しました。`}</p>
    <p>{`注意点としては、複数の DockerImage を利用する場合、一つ目に指定した image が primary として扱われます。`}</p>
    <p>{`以下の例ですと、Ruby の image を最初に指定し、MySQL、Redis の image を指定していますが、MySQL コマンド自体は Ruby の image に含まれていないため、Ruby コマンドを実行できても MySQL コマンドを実行することは出来ません。`}</p>
    <p>{`※詳細は`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/configuration-reference/#docker"
      }}>{`こちら`}</a>{`に記載されています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`docker`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` circleci/ruby`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`2.4.2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`browsers
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /usr/share/zoneinfo/Asia/Tokyo
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` circleci/mysql`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`x.x.x
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /usr/share/zoneinfo/Asia/Tokyo
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` redis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`x.x.x
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /usr/share/zoneinfo/Asia/Tokyo`}</code></pre></div>
    <p>{`また、用意されている image をカスタマイズする必要がある場合は、Docker でカスタム image を作り、public で良ければ`}<a parentName="p" {...{
        "href": "https://hub.docker.com/"
      }}>{`Docker Hub`}</a>{`へ登録、private が良ければ`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/ecr/"
      }}>{`Amazon EC2 Container Registry`}</a>{`へ登録しておくことで呼び出すことも可能になっています。`}</p>
    <p>{`※Using Custom-Built Docker Images
`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/custom-images/"
      }}>{`https://circleci.com/docs/ja/2.0/custom-images/`}</a></p>
    <p>{`※Using Private Images
`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/private-images/"
      }}>{`https://circleci.com/docs/ja/2.0/private-images/`}</a></p>
    <h1>{`build の設定と cache`}</h1>
    <p>{`CI で実行するアプリケーションの build に関してです。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`checkout`}</code>{`で github からコードを checkout し、その後の定義でアプリケーションのインストール、キャッシュ保存、キャッシュの展開を行っています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` checkout
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Rails application setup`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restore_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checksum "Gemfile.lock" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle install
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle install `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`jobs=4 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`path=vendor/bundle
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`save_cache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`key`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` checksum "Gemfile.lock" `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`paths`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` vendor/bundle`}</code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`save_cache:`}</code>{` key に Gemfile.lock を指定することでキャッシュキーとして扱い、paths に設定したパスをキャッシュするようにしています。`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`restore_cache:`}</code>{` key と一致するキャッシュがあれば、save_cache 時に指定したパスを展開し直しています。`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`run:`}</code>{` こちらは rails のアプリケーションインストールしているだけです。`}</li>
    </ul>
    <p>{`CircleCI1.0 よりもキャッシュ管理を柔軟に行えることがわかります。`}</p>
    <h1>{`circleci コマンドによる Rspec の並列実行`}</h1>
    <p>{`rspec によるテストの実行に関してです。
`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/parallelism-faster-jobs/#supported-globbing-patterns"
      }}>{`circleci コマンド`}</a>{`を利用することでテストの並列実行を効率的に行うことが出来るます。`}</p>
    <p>{`今回は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--split-by=timings --timings-type=filename`}</code>{` のオプションを指定し、ファイル名ベースでの分割でテストを実行します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` run test
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token scalar string"
          }}>{`
      circleci tests glob 'spec/**/*_spec.*' \\
        | circleci tests split --split-by=timings --timings-type=filename \\
        | tee -a /dev/stderr \\
        | xargs bundle exec rspec \\
        --profile 100 \\
        --format RspecJunitFormatter \\
        --out rspec/rspec.xml \\
        --format progress`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`RAILS_ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` test
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`TEST_CLUSTER_COMMAND`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`x.x.x/bin/elasticsearch
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`store_artifacts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` artifacts/
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`store_test_results`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` rspec/`}</code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`store_artifacts:`}</code>{` 以前からもある機能ですが、テスト結果の成果物を保存するパスになります。`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`store_test_results:`}</code>{` こちらはテストの実行結果を保存しておくことで、コンテナ間で rspec の実行時間にばらつきが出ないよう、対象のファイルを最適に振り分けてくれるようなのですが、workflows を利用するとサポートされないようです。`}</li>
    </ul>
    <p>{`※参考： `}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/configuration-reference/#store_test_results"
      }}>{`https://circleci.com/docs/ja/2.0/configuration-reference/#store_test_results`}</a></p>
    <p>{`このような形で Artifacts が保存されています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024101642.png",
      "alt": "20171024101642.png"
    }}></img>
    <p>{`また、artifacts には coverage と capybara の screenshot などを保存しています`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` simple_cov
  if ENV`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'CI'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  SimpleCov.coverage_dir File.join(ENV`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'CIRCLE_WORKING_DIRECTORY'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'artifacts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 'coverage')
  SimpleCov.formatter = SimpleCov`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Formatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`MultiFormatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  SimpleCov`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Formatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`HTMLFormatter
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  SimpleCov.start do
  add_filter '/vendor/'
  add_filter '/spec/'
  add_filter '/config/'
  add_filter '/db/'
  end
  end`}</code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`capybara`}</code>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`Capybara.save_and_open_page_path = File.join(ENV['CIRCLE_WORKING_DIRECTORY'], 'artifacts', 'capybara') if ENV['CI']`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`※CircleCI2.0 から CI の環境変数が変わっています。詳細は以下のリンクへ記載されています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/env-vars/#circleci-environment-variable-descriptions"
      }}>{`https://circleci.com/docs/ja/2.0/env-vars/#circleci-environment-variable-descriptions`}</a></p>
    <h1>{`Workflows の設定`}</h1>
    <p>{`CircleCI2.0 から Workflows の利用が可能になりました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/workflows/"
      }}>{`https://circleci.com/docs/ja/2.0/workflows/`}</a></p>
    <p>{`コンテナ毎に分割しジョブを実行することで更なる並列実行の効率化、及び、ジョブ間の依存関係まで設定できるようです。
ジョブメドレーではコードの静的解析に少し実行時間が掛かっていることから、多少の改善を図れると考え`}<del parentName="p">{`単純に使ってみたかった`}</del>{`こちらの機能を利用してみました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`workflows`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`workflows`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`jobs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`code_analyze`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ignore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /^sandbox.`}<span parentName="code" {...{
            "className": "token important"
          }}>{`*|^master$|^staging$/`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`rspec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ignore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /^sandbox.`}<span parentName="code" {...{
            "className": "token important"
          }}>{`*|^master$|^staging$/`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`deploy_qa`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` code_analyze
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` rspec
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` develop
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`deploy_only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`requires`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` build
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`filters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`branches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /^sandbox.`}<span parentName="code" {...{
            "className": "token important"
          }}>{`*|^master$|^staging$/`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`build:`}</code>{` 各ジョブで実行前に行っておく処理を定義`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`code_analyze:`}</code>{` rubocop、brakeman、scss-lint などの静的解析処理を定義`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`rspec:`}</code>{` アプリケーションのテストを定義`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`deploy:`}</code>{` デプロイに関する処理を定義`}</li>
    </ul>
    <p>{`ジョブメドレーでは、ブランチ管理に Git-flow を採用していますが、それとは別に sandbox というテスト環境を用意し運用しています。
develop ブランチでコード解析やテストをクリアしたコードだけ、master へ反映し、master ではテストフェーズなしに deploy する構成を取っています。
極力、CI のリソースを節約するように各ブランチごとで実行する処理を分けています。`}</p>
    <p>{`各ブランチの運用は以下の通りです。`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`feature:`}</code>
        <ul parentName="li">
          <li parentName="ul">{`コード解析、テスト実行`}</li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`sandbox:`}</code>
        <ul parentName="li">
          <li parentName="ul">{`デプロイのみ実行(一時レビュー用ブランチ)`}</li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`develop:`}</code>
        <ul parentName="li">
          <li parentName="ul">{`コード解析、テスト実行、デプロイ実行`}</li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`master:`}</code>
        <ul parentName="li">
          <li parentName="ul">{`デプロイのみ実行`}</li>
        </ul>
      </li>
    </ul>
    <p>{`上記の例では、`}</p>
    <ul>
      <li parentName="ul">{`code_analyze:`}
        <ul parentName="li">
          <li parentName="ul">{`sandbox、master、staging ブランチ以外は実行`}</li>
          <li parentName="ul">{`requires で依存関係を指定し、build が正常に終了しなければ実行されないようになっています。`}</li>
        </ul>
      </li>
      <li parentName="ul">{`rspec:`}
        <ul parentName="li">
          <li parentName="ul">{`sandbox、master、staging ブランチ以外は実行`}</li>
          <li parentName="ul">{`requires で依存関係を指定し、build が正常に終了しなければ実行されないようになっています。`}</li>
        </ul>
      </li>
      <li parentName="ul">{`deploy_qa:`}
        <ul parentName="li">
          <li parentName="ul">{`develop ブランチでのみ実行`}</li>
          <li parentName="ul">{`requires で依存関係を指定し、code_analyze、rspec が正常に終了しなければ実行されないようになっています。`}</li>
        </ul>
      </li>
      <li parentName="ul">{`deploy_only:`}
        <ul parentName="li">
          <li parentName="ul">{`sandbox、master、staging ブランチのみ利用するジョブ`}</li>
          <li parentName="ul">{`requires で依存関係を指定し、build が正常に終了しなければ実行されないようになっています。`}</li>
        </ul>
      </li>
    </ul>
    <p>{`どのような workflow が出来あがるのか、以下に例を示します。`}</p>
    <h3>{`feature ブランチの例:`}</h3>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024101757.png",
      "alt": "20171024101757.png"
    }}></img>
    <h3>{`develop ブランチの例:`}</h3>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024101818.png",
      "alt": "20171024101818.png"
    }}></img>
    <h3>{`master、sandbox ブランチの例:`}</h3>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024101852.png",
      "alt": "20171024101852.png"
    }}></img>
    <p>{`今回の例だとブランチ毎に workflow を変えているため、ignore、only の書き方で意図せず振り分けされないように考慮は必要ですが、柔軟に workflow を作れることがわかると思います。(やり過ぎると読み解くのが大変になりそうですね)`}</p>
    <p>{`Workflows に関しては`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/workflows/"
      }}>{`こちら`}</a>{`に色々なパターンの組み方が記載されているので、こちらを読むとより理解が深まると思います。`}</p>
    <h1>{`ジョブ間でのデータ共有`}</h1>
    <p>{`ジョブを分けてビルドする=何回もアプリケーションの初期化が必要なんじゃないか？
と当然疑問に思う点ではありますが、それに対する解決策も用意されています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    ===省略===
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`persist_to_workspace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`root`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`paths`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` ./*

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`deploy_qa`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token important"
          }}>{`*defaults`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`attach_workspace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ~/job`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`medley`}</code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`persist_to_workspace:`}</code>{` 指定したパスにあるデータを一時的に保管してくれます`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`attach_workspace:`}</code>{` 保管済みのデータを展開してくれます`}</li>
    </ul>
    <p>{`この機能により、ビルドプロセスで生成したものを各ジョブで実行するコンテナへ渡すことが出来ます。
ただし、そもそもビルドプロセスでキャッシュを入れていることもあり、これ自体の効果は殆どありませんでした。
コンパイル済みのデータを受け渡す際には効果を発揮しそうですね。(`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/workflows/#using-workspaces-to-share-data-among-jobs"
      }}>{`公式でも`}</a>{`そのような利用を想定していそうです)`}</p>
    <h1>{`改善結果`}</h1>
    <p>{`肝心の速度改善結果です。結果は以下の通りになりました。`}</p>
    <h2>{`改善前: CircleCI1.0`}</h2>
    <p>{`rspec の実行時間: 26:59`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171025/20171025162233.png",
      "alt": "20171025162233.png"
    }}></img>
    <p>{`以下は、CircleCI2.0 へ移行しただけの結果です。このケースでは workflows を利用していません。`}</p>
    <h2>{`改善後: CircleCI2.0`}</h2>
    <p>{`rspec の実行時間: 19:40`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024102115.png",
      "alt": "20171024102115.png"
    }}></img>
    <p>{`CircleCI1.0 から CircleCI2.0 へ移行することにより、`}<strong parentName="p">{`約 12 分程`}</strong>{`テストの実行時間を短縮することが出来ました。
Workflows など特に利用していない、かつ、ビルドフェーズの実行時間も関係しないため、CircleCI2.0 を利用するだけで単純にテスト実行速度の向上を見込めることがわかると思います。`}</p>
    <p>{`続いて Workflows を利用した結果です。`}</p>
    <h2>{`改善後: CircleCI2.0 with Workflows`}</h2>
    <p>{`rspec の実行時間: 21:14`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171024/20171024102148.png",
      "alt": "20171024102148.png"
    }}></img>
    <p>{`結果は、Workflows を利用しないケースと、利用したケースでは、Workflows を利用したほうが rspec の実行時間は長くなってしまいましたが、build-code-analyze-rspec の実行に掛かったトータルの時間に差は見られませんでした。`}</p>
    <p>{`これは、「circleci コマンドによる Rspec の並列実行」のセクションへも記載した通り、store_test_results がサポートとされないことにより、コンテナ間での分散が最適化されていない為です。`}</p>
    <blockquote>
      <p parentName="blockquote">{`コンテナ間で rspec の実行時間にばらつきが出ないよう、対象のファイルを最適に振り分けてくれるようなのですが、Workflows を利用するとサポートされないようです。`}</p>
    </blockquote>
    <p>{`実行時間にばらつきが出てしまい、code_analyze のジョブを分散することで見込んでいた改善時間(約 3 分)とばらつきにより発生したテスト実行時間のロス( (20:01 - 14:57) / 2 = 2:32 )が大体同じであるため、トータルでの実行時間に差が出ない結果となりました。`}</p>
    <p>{`ばらつきを出さない方法や、ジョブの分け方については今後も工夫してみたいと思います。
また、フロントエンドのテストをもう少し厚くしていきたいと考えているので、フロントエンドのテスト、サーバサイドのテストを Workflows を上手く使いながら分散していければ良いのかなとも思っています。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`移行に際して、`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/migrating-from-1-2/"
      }}>{`CircleCI2.0 の移行ガイド`}</a>{`を読みながら進めていましたが、基本的な記法の変更、timezone、environment の定義方法の変更、variable の変更などが多々有り、ドキュメントを結構読み込まないとどこに何が定義できるのか把握できませんでした。`}</p>
    <p>{`また、Workflows の組み立てなど`}<a parentName="p" {...{
        "href": "https://github.com/CircleCI-Public/circleci-demo-workflows/tree/try-schedule-workflow"
      }}>{`公式に良いサンプル`}</a>{`は沢山あるのですが、依存関係の定義を色々試すのに苦労した気がします。`}</p>
    <p>{`※素直に小規模なアプリを用意して、`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/local-jobs/"
      }}>{`ローカルで circleci を実行`}</a>{`してみた方が効率良く進められたかもしれません。`}</p>
    <p>{`※ただ、`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/"
      }}>{`公式のドキュメント`}</a>{`や`}<a parentName="p" {...{
        "href": "https://discuss.circleci.com/"
      }}>{`CommunityForum`}</a>{`をしっかり読めば余すことなく情報は合ったので非常に助かりました。`}</p>
    <p>{`CircleCI2.0 でどのような事が出来るのか、それはどのように行えるのか。
この記事がその概観をつかむ助けになれば良いなと思っています。`}</p>
    <h1>{`参考リンク`}</h1>
    <p>{`CircleCI2.0 へ移行するにあたり、以下の記事を参考にさせていただきました。ありがとうございます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://qiita.com/inuscript/items/09d15ee52b1657872f80"
      }}>{`https://qiita.com/inuscript/items/09d15ee52b1657872f80`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@timakin/circleci2-0%E3%81%AEworkflows%E3%82%92%E8%A9%A6%E3%81%99-1329042122fd"
      }}>{`https://medium.com/@timakin/circleci2-0%E3%81%AEworkflows%E3%82%92%E8%A9%A6%E3%81%99-1329042122fd`}</a></p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      